const BroadcastBar = (props) => (
    <div className="x-broadcast">
        <div className="x-broadcast-wrapper">
        <div className="x-broadcast-content">
            <img
            src="images/shirt.png"
            loading="lazy"
            alt=""
            className="x-broadcast-icon"
            />
            <div className="x-broadcast-label">
            <strong>NEW! Wash, Dry, Steam Iron Service</strong>
            <br />
            </div>
        </div>
        <div className="x-btn-container">
            <a
            href="https://youtu.be/JbDvSroK9g4"
            target="_blank"
            className="x-sml-btn for-secondary w-button"
            rel="noreferrer"
            >
            Watch<span className="x-icon for-btn-inline"></span>
            </a>
        </div>
        </div>
    </div>
  )
  
  export default BroadcastBar