import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MainContainer from "../Components/MainContainer";
import Header from "../Components/Header";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import NewOrderStepper from "../Components/NewOrderStepper";
import NewOrderFooter from "../Components/NewOrderFooter";
import ScheduleModals from "../Components/Modals/ScheduleModals";
import ErrorModal from "../Components/Modals/ErrorModal";

import { currentOrderAtom, orderBagsAtom, timeslotsAtom } from "../Utils/Atoms";
import OrderObj from "../Utils/OrderObj";
import { convertTimeToMinutes, isNextDayDelivery } from "../Utils/Dates";
import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";
import { filterLaundryOrderBags } from "../Utils/Helpers";

function Schedule() {
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [timeslots, setTimeslots] = useRecoilState(timeslotsAtom);
  const [showModal, setShowModal] = useState(false);
  const [modalCode, setModalCode] = useState(0);
  // const [selectedPickupDay, setSelectedPickupDay] = useState('')
  // const [selectedDeliveryDay, setSelectedDeliveryDay] = useState('')
  const [minDate, setMinDate] = useState(new Date());
  const [pickupDate, setPickupDate] = useState(new Date());
  const [pickupSlot, setPickupSlot] = useState("");
  const [filteredTimeslots, setFilteredTimeslots] = useState([]);
  const [showNextDayDeliveryMsg, setShowNextDayDeliveryMsg] = useState(false);

  const [haveError, setHaveError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const nav = useNavigate();

  const navigateContinue = (e: any): void => {
    e.preventDefault();

    // Validation
    // if (currentOrder.pickup_delivery_remarks.length === 0) {
    //   setHaveError(true)
    //   setErrorMessage('Please add pickup remarks')
    //   setShowModal(true)
    //   return
    // }
    // if (currentOrder.delivery_remarks.length === 0) {
    //   setHaveError(true)
    //   setErrorMessage('Please add delivery remarks')
    //   setShowModal(true)
    //   return
    // }

    let updated = Object.assign(new OrderObj(), currentOrder);
    updated["pickup_schedule_date"] = pickupDate;
    updated["pickup_slot"] = pickupSlot;
    updated["pickup_slot_id"] = pickupSlot;
    // Find in timeslots to update string value
    let slotObj = timeslots.find((slot: any) => Number(pickupSlot) === slot.id);
    updated[
      "pickup_schedule_slot"
    ] = `${slotObj.slot_start} - ${slotObj.slot_end}`;
    // Updating order obj
    setCurrentOrder(updated);

    nav("/address-select");
  };

  const handleOpenModal = (e: any, modalNumber: number) => {
    e.preventDefault();
    setShowModal(true);
    setModalCode(modalNumber);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setModalCode(0);
    // Reset error modal
    setHaveError(false);
    setErrorMessage("");
  };

  const generateModalContent = () => {
    if (haveError) return <ErrorModal description={errorMessage} />;
    return <ScheduleModals modalCode={modalCode} />;
  };

  const displayPickupTimes = (setDefault: boolean, date: any, slot: any) => {
    const todayDate = moment().format("YYYYMMDD");
    const currentTime = moment().format("HH:mm");
    let selectedDay = moment(date).format("dddd").toLowerCase();
    let selectedDate = moment(date).format("YYYYMMDD");
    let filteredSlots = [];

    // Filtering timeslots
    if (todayDate !== selectedDate) {
      filteredSlots = timeslots.filter((item: any) => item.day == selectedDay);
    } else {
      filteredSlots = timeslots.filter((item: any) => {
        if (item.day !== selectedDay) return false; // Check if the slot is for the current day
        const slotStartMinutes = convertTimeToMinutes(item.slot_start);
        const currentMinutes = convertTimeToMinutes(currentTime);
        return slotStartMinutes > currentMinutes;
      });
    }
    setFilteredTimeslots(filteredSlots);

    const isSlotStillAvailable = filteredSlots.find(
      (item: any) => item.id == slot
    );

    if (slot && isSlotStillAvailable) {
      handleSetPickupSlot(slot);
    } else {
      if (setDefault) {
        if (filteredSlots.length) {
          handleSetPickupSlot(filteredSlots[0]?.id);
        } else {
          handleSetPickupSlot("0");
        }
      }
    }
  };
  const handleSetPickupSlot = (slot: any) => {
    setPickupSlot(slot);
    const selected = timeslots.find((item: any) => item.id == slot);
    setShowNextDayDeliveryMsg(isNextDayDelivery(selected?.slot_start));
  };

  const handlePickUpSelection = (e: any) => {
    let newDate = e;
    setPickupDate(newDate);

    // Filtering timeslots
    displayPickupTimes(true, newDate, "");
  };

  const handleChange = (e: any): void => {
    let updated = Object.assign(new OrderObj(), currentOrder);
    let target = e.target.id;
    let newValue = e.target.value;

    if (target === "pickup_slot") {
      handleSetPickupSlot(newValue);
      updated[target] = Number(newValue);
    } else {
      updated[target] = newValue;
    }

    setCurrentOrder(updated);
  };

  

  const getTimeslots = async () => {
    // get an set timeslots from API
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_TIMESLOTS);

      const allTimeslots = response.data;

      setTimeslots(allTimeslots);

      if (allTimeslots?.length) {
        const currentDateTime = moment().toDate();

        // Filter timeslots based on today's day
        let currentTimeslots = allTimeslots.filter(
          (slot: any) =>
            slot.day == moment(currentDateTime).format("dddd").toLowerCase()
        );

        // Find last slot available for today
        const lastSlotAvailable = currentTimeslots.reduce(
          (latestSlot: any, currentSlot: any) => {
            const latestStart = convertTimeToMinutes(latestSlot.slot_start);
            const currentStart = convertTimeToMinutes(currentSlot.slot_start);
            return latestStart > currentStart ? latestSlot : currentSlot;
          }
        );

        // Format last slot
        const formattedLastSlot = moment(
          lastSlotAvailable.day + " " + lastSlotAvailable.slot_start,
          "dddd ha"
        ).toDate();

        // Compare current time with last slot, set minDate
        let _minDate = currentDateTime;
        if (moment().isAfter(formattedLastSlot)) {
          _minDate = moment().add(1, "day").toDate();
        }

        setMinDate(_minDate);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const checkOrderBag = () => {
      const filteredBag = filterLaundryOrderBags(orderBags);
      if (filteredBag.length === 0) {
        // go back to new order page
        return nav('/new-order');
      }
      getTimeslots();
    }

    checkOrderBag();
  }, []);

  useEffect(() => {
    retrieveSelectedPickup(minDate);
  }, [minDate]);

  const retrieveSelectedPickup = (_minDate: any) => {
    let updated = Object.assign(new OrderObj(), currentOrder);
    let selectedPickupDate = updated["pickup_schedule_date"];
    let selectedPickupSlot = updated["pickup_slot"];

    if (selectedPickupDate && selectedPickupDate.length !== 0) {
      const _selectedDate = moment(selectedPickupDate).format("YYYYMMDD");
      let newSelectedDate = selectedPickupDate;
      let newSelectedSlot = selectedPickupSlot;
      if (moment(_selectedDate).isBefore(moment(_minDate).format("YYYYMMDD"))) {
        newSelectedDate = _minDate;
        newSelectedSlot = "";
      }
      setPickupDate(new Date(newSelectedDate));
      displayPickupTimes(true, newSelectedDate, newSelectedSlot);
    } else {
      setPickupDate(_minDate);
      displayPickupTimes(true, _minDate, "");
    }
  };

  const getFilteredTimeslots = () => {
    return filteredTimeslots.map((slot: any) => (
      <option
        key={slot.id}
        value={slot.id}
      >{`${slot.slot_start} - ${slot.slot_end}`}</option>
    ));
  };

  return (
    <MainContainer title={"Select Schedule"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Book Laundry Service</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-order-header">
              <div className="x-text-flex">
                <a
                  href="/new-order"
                  className="x-icon-btn for-secondary is-back w-button"
                >
                  
                </a>
                <h2>Schedule</h2>
                <a
                  data-toggle="dialog-schedule-guide"
                  data-tippy-content="How this works?"
                  onClick={(e) => handleOpenModal(e, 1)}
                  className="x-helper-btn"
                >
                  
                </a>
              </div>
              <NewOrderStepper step={2} />
            </div>
            <div className="x-order-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Order-Schedule-Form"
                  name="wf-form-Order-Schedule-Form"
                  data-name="Order Schedule Form"
                  method="get"
                  data-wf-page-id="64fc899ba1040710e6eba953"
                  data-wf-element-id="6ca47c44-3a43-0c2f-1bbd-1aa46a68960f"
                >
                  <div className="x-form-body">
                    <div className="x-address-manager">
                      <div
                        data-address="pickup"
                        className="x-address-manager-row"
                      >
                        <div className="x-bag-item-header">
                          <h3>Pickup Timing</h3>
                        </div>
                        <div className="x-form-container">
                          <div className="x-form-row">
                            <div className="x-form-col for-med">
                              <label htmlFor="PickupDate">Pickup Date</label>
                              <DatePicker
                                minDate={minDate}
                                dateFormat="dd/MM/yyyy"
                                selected={pickupDate}
                                onChange={handlePickUpSelection}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                onFocus={(e) => (e.target.readOnly = true)}
                                className="x-input-text for-datepicker is-single-date w-input"
                              />
                              <div
                                data-tippy-content="Pick a date range"
                                className="x-icon for-input-info"
                              >
                                
                              </div>
                            </div>
                          </div>
                          <div className="x-form-row">
                            <div className="x-form-col for-med">
                              <label htmlFor="PickupTime">Pickup Time</label>
                              <select
                                id="pickup_slot"
                                name="PickupTime"
                                data-name="PickupTime"
                                value={pickupSlot}
                                onChange={handleChange}
                                className="x-input-select for-sl-single w-select"
                              >
                                {getFilteredTimeslots()}
                              </select>
                            </div>
                          </div>
                          {showNextDayDeliveryMsg && (
                            <div className="x-helper-block">
                              <div className="x-icon for-btn-inline"></div>
                              <div>
                                <div className="x-emp-text">
                                  Your Delivery will be Next Day
                                </div>
                                <div>
                                  Pickup time after 12:30pm will be delivered
                                  back to you the next day. Only applicable for express delivery. 
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="x-form-row">
                            <div className="x-form-col">
                              <label htmlFor="PickupRemarks">
                                Pickup Remarks
                              </label>
                              <textarea
                                id="pickup_delivery_remarks"
                                name="PickupRemarks"
                                maxLength={240}
                                data-name="PickupRemarks"
                                className="x-input-textarea w-input"
                                value={currentOrder.pickup_delivery_remarks}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* DELIVERY */}
                      <div
                        data-address="pickup"
                        className="x-address-manager-row"
                      >
                        {/* <div className="x-bag-item-header">
                          <h3>Delivery Timing</h3>
                        </div> */}
                        <div className="x-form-container">
                          <div className="x-helper-block for-green">
                            <div className="x-icon for-btn-inline is-green">
                              
                            </div>
                            <div>
                              <div className="x-emp-text">
                                When&#x27;s my Delivery Timing?
                              </div>
                              <div>
                                Delivery timing is dependent on your shipping
                                method, which can be set in the next step.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <NewOrderFooter
                      goToNext={(e: any) => navigateContinue(e)}
                      buttonText={"Continue"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-schedule-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc899ba1040710e6eba953"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default Schedule;
