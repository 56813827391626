import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";

import MainContainer from "../Components/MainContainer";
import Header from "../Components/Header";
import HomeFooter from "../Components/HomeFooter";
import Footer from "../Components/Footer";
import NewOrderStepper from "../Components/NewOrderStepper";
import NewOrderModals from "../Components/Modals/NewOrderModals";
import NewOrderFooter from "../Components/NewOrderFooter";
import ErrorModal from "../Components/Modals/ErrorModal";
// import NewOrderProductItem from '../Components/NewOrderProductItem';
// import NewOrderSubProductItem from '../Components/NewOrderSubProductItem';
import NewOrderBagListItem from "../Components/NewOrderBagListItem";
import NewOrderBag from "../Components/NewOrderBag";

// import { getProduct, getSubproduct } from "../Utils/Products";
import {
  accessTokenAtom,
  currentBagAtom,
  currentOrderAtom,
  isSignedInAtom,
  orderBagsAtom,
  productsAtom,
  profileAtom,
  refreshTokenAtom,
  showBagFormAtom,
} from "../Utils/Atoms";
import OrderBagObj from "../Utils/OrderBagObj";
import { ApiInstance } from "../Utils/ApiInstance";
import ApiRoutes from "../Routes/ApiRoutes";
import { clearCurrentOrder, filterLaundryOrderBags } from "../Utils/Helpers";
import OrderObj from "../Utils/OrderObj";

function NewOrder() {
  const [signedIn, setSignedIn] = useRecoilState(isSignedInAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);

  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [currentBag, setCurrentBag] = useRecoilState(currentBagAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  // const [laundryOrderBags, setLaundryOrderBags] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalCode, setModalCode] = useState(0);
  const [laundryProducts, setLaundryProducts] = useState([]);
  const [bagAmount, setBagAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [haveError, setHaveError] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const nav = useNavigate();

  const goToNext = (e: any) => {
    e.preventDefault();

    const filteredBag = filterLaundryOrderBags(orderBags);

    // Validation
    if (filteredBag.length === 0) {
      setHaveError(true);
      setErrorMessage("Please add a bag to cart to continue to schedule");
      setShowModal(true);
      return;
    }

    nav("/schedule");
  };

  const handleOpenModal = (e: any, modalNumber: number) => {
    e?.preventDefault();
    setShowModal(true);
    setModalCode(modalNumber);
  };

  const handleCloseModal = async (e: any) => {
    e.preventDefault();

    if (errorCode === 401 || errorCode === 403) {
      await handleLogout(e);
    }
    setShowModal(false);
    setModalCode(0);
    // Reset error modal
    setHaveError(false);
    setErrorMessage("");
  };

  const generateModalContent = () => {
    if (haveError || modalCode === 0)
      return <ErrorModal description={errorMessage} />;
    return <NewOrderModals modalCode={modalCode} />;
  };

  const handleLogout = async (e: any) => {
    e.preventDefault();

    setSignedIn(false);
    setProfile([]);
    setCurrentOrder(new OrderObj());
    setOrderBags([]);
    setProducts([]);
    setAccessToken("");
    setRefreshToken("");
    setShowBagForm(true);
    try {
      await ApiInstance.post(ApiRoutes.REVOKE_TOKEN, {});
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
      handleOpenModal(null, 0);
    } finally {
      nav("/");
    }
  };

  const handleAddNewBag = (e: any): void => {
    // reset currentBag to new bag object
    setCurrentBag(new OrderBagObj());
    // show the form
    setShowBagForm(true);
  };

  const getLaundryProducts = () => {
    let list = products.filter(
      (prod: any) => prod.category === "laundry" && prod.status === 1
    );
    setLaundryProducts(list);
  };

  const getProducts = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PRODUCTS);
      setProducts(response.data);
      getLaundryProducts();
    } catch (error: any) {
      setErrorCode(error.response?.status);
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
      handleOpenModal(null, 0);
    }
  };

  const checkNoBag = () => {
    const filteredBag = filterLaundryOrderBags(orderBags);
    // setLaundryOrderBags(reorderBags(filteredBag));

    if (filteredBag.length === 0) {
      // Reset all data
      setCurrentOrder(clearCurrentOrder(currentOrder));
      setOrderBags([]);
      // reset currentBag to new bag object
      setCurrentBag(new OrderBagObj());
      // show the form
      setShowBagForm(true);
    }
  };

  // const calculateLaundrySubtotal = () => {
  //   let subtotal = 0;
  //   orderBags.map((bag: any) => {
  //     let product = getProduct(bag.product_id, products);

  //     if (product?.category == "laundry") {
  //       if (product.variant_type == "quantity") {
  //         subtotal += Number(bag.product_qty) * Number(product.unit_price);
  //       } else if (product.variant_type == "variant") {
  //         let subproduct = getSubproduct(bag.subproduct_id, product.subproducts);
  //         subtotal += Number(subproduct.unit_price);
  //       }
  //     }
  //   });
  //   return Number(subtotal).toFixed(2);
  // }

  useEffect(() => {
    getProducts();
    // Always init new bag if no bag
    checkNoBag();
  }, []);

  let bagIdx = 0;

  return (
    <MainContainer title={"Create Order"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Book Laundry Service</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-order-header">
              <div className="x-text-flex">
                <h2>Your bags</h2>
                <a
                  data-toggle="dialog-page-guide"
                  data-tippy-content="How this works?"
                  onClick={(e) => handleOpenModal(e, 1)}
                  className="x-helper-btn"
                >
                  
                </a>
              </div>
              <NewOrderStepper step={1} />
            </div>
            <div className="x-order-wrapper">
              <div className="x-form-wrapper w-form">
                <form
                  id="wf-form-Order-Builder-Form"
                  name="wf-form-Order-Builder-Form"
                  data-name="Order Builder Form"
                  method="get"
                  data-wf-page-id="64fb5b0e4549d64251e557bb"
                  data-wf-element-id="6ca47c44-3a43-0c2f-1bbd-1aa46a68960f"
                >
                  <div className="x-form-body">
                    <ol role="list" className="x-bag-list w-list-unstyled">
                      {/* SHOWING MINIMISED BAGS */}
                      {orderBags.length > 0 &&
                        orderBags.map((bag: OrderBagObj, index: number) => {
                          if (bag.category === "laundry") {
                            bagIdx++;
                            return (
                              <NewOrderBagListItem
                                key={index}
                                id={bagIdx}
                                bag={bag}
                              />
                            );
                          }
                        })}
                      {/* SHOWING NEW ORDER BAG */}
                      {showBagForm && (
                        <NewOrderBag
                          bag={currentBag}
                          handleOpenModal={(e: any, index: number) =>
                            handleOpenModal(e, index)
                          }
                          checkNoBag={checkNoBag}
                        />
                      )}
                    </ol>
                    <div style={{ paddingRight: 24, paddingLeft: 24 }}>
                      <a
                        onClick={handleAddNewBag}
                        className=""
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="x-icon for-btn-inline"></span>
                        &nbsp;Add another bag
                      </a>
                    </div>
                    <NewOrderFooter
                      goToNext={(e: any) => goToNext(e)}
                      buttonText={"Schedule Now"}
                      disabled={filterLaundryOrderBags(orderBags).length === 0}
                    />
                  </div>
                </form>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>

      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-page-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fb5b0e4549d64251e557bb"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        {errorCode === 401 || errorCode === 403
                          ? "Logout"
                          : "Dismiss"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default NewOrder;
