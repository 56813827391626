import React from "react";

interface WdsiModalProps {
  showModal: boolean;
  handleCloseModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const WdsiModal: React.FC<WdsiModalProps> = ({
  showModal,
  handleCloseModal,
}) => {
  return (
    <div
      data-toggletarget="dialog-checkout-agreement"
      className={`x-dialog ${showModal ? "" : "for-hide"}`}
    >
      <div className="x-dialog-wrapper">
        <div className="x-dialog-form w-form">
          <form
            id="wf-form-Guide-Dialog-Form"
            name="wf-form-Guide-Dialog-Form"
            data-name="Guide Dialog Form"
            method="get"
            data-wf-page-id="64fd66bdcf6f93409fa6c3a8"
            data-wf-element-id="67a72fc4-06ef-556c-a032-59011035f52e"
            aria-label="Guide Dialog Form"
          >
            <div className="x-dialog-header">
              <div className="x-dialog-title for-action-icon"></div>
              <div className="x-btn-container">
                <a
                  onClick={(e) => handleCloseModal(e)}
                  className="x-icon-btn for-simple is-close-modal w-button"
                >
                  
                </a>
              </div>
            </div>
            <div className="x-dialog-body">
              <div className="x-form-wrapper">
                <div className="x-form-row">
                  <div className="x-form-col">
                    <img
                      alt=""
                      src="https://assets-global.website-files.com/64f4a348be566b5a01f37b12/653b8d8ae82234d2f822b6d4_spam-line.svg"
                      className="x-message-thumb for-dialog"
                    />
                    <div className="x-dialog-title for-action">
                      Important notes for Wash, Dry, Steam Iron
                    </div>
                    <p>
                      1) Please ensure that the material of your clothing is
                      suitable for washing, drying, and steam ironing by
                      checking the clothing label before sending it to us.
                    </p>
                    <p>
                      Suggested clothing: Daily clothing, tudung (We do not tend
                      to large items like bedsheets, linen for Steam Iron
                      service).
                    </p>
                    <img
                      src="images/wdsi.png"
                      loading="lazy"
                      sizes="100vw"
                      srcset="images/wdsi.png 500w, images/wdsi.png 800w, images/wdsi.png 1080w, images/wdsi.png 1496w"
                      alt=""
                    />
                    <p>
                      2) Please note that any damages resulting from unsuitable
                      materials will fall under the full responsibility of
                      customer.
                    </p>
                    <p>
                      3) If you did not provide or purchase hangers, we will
                      return your laundry in stacked manner.
                    </p>
                    <p className="x-emp-text">
                      By continuing, you agree to the above and dobiQueen&#x27;s{" "}
                      <a href="https://www.dobiqueen.my/tnc" target="_blank">
                        Terms &amp; Conditions
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="x-dialog-action">
              <div className="x-btn-container">
                <a
                  onClick={(e) => handleCloseModal(e)}
                  className="x-btn w-button"
                >
                  I have read and understood these guidelines
                </a>
              </div>
            </div>
          </form>
          <div
            className="w-form-done"
            tabIndex="-1"
            role="region"
            aria-label="Guide Dialog Form success"
          >
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div
            className="w-form-fail"
            tabIndex="-1"
            role="region"
            aria-label="Guide Dialog Form failure"
          >
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
        <div className="x-dialog-overlay"></div>
      </div>
    </div>
  );
};
