import { useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import MainContainer from "../../../Components/MainContainer";
import HomeFooter from "../../../Components/HomeFooter";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header";
import ErrorModal from "../../../Components/Modals/ErrorModal";

import OrderObj from "../../../Utils/OrderObj";
import {
  accessTokenAtom,
  isSignedInAtom,
  profileAtom,
  refreshTokenAtom,
  orderBagsAtom,
  productsAtom,
  currentOrderAtom,
  showBagFormAtom,
} from "../../../Utils/Atoms";
import { ApiInstance, ApiRoutes } from "../../../Utils/ApiInstance";

function SettingsMain() {
  const [signedIn, setSignedIn] = useRecoilState(isSignedInAtom);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
  const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom);
  const [orderBags, setOrderBags] = useRecoilState(orderBagsAtom);
  const [showBagForm, setShowBagForm] = useRecoilState(showBagFormAtom);
  const [products, setProducts] = useRecoilState(productsAtom);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleOpenModal = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setShowModal(true);
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    setShowModal(false);
  };

  const generateModalContent = () => {
    return <ErrorModal description={errorMessage} />;
  };

  const getProfile = async () => {
    try {
      const response = await ApiInstance.get(ApiRoutes.GET_PROFILE);
      setProfile(response.data);
    } catch (error) {
      throw error;
    }
  };

  const handleLogout = async (e: any) => {
    e.preventDefault();

    setSignedIn(false);
    setProfile([]);
    setCurrentOrder(new OrderObj());
    setOrderBags([]);
    setProducts([]);
    setAccessToken("");
    setRefreshToken("");
    setShowBagForm(true);
    try {
      await ApiInstance.post(ApiRoutes.REVOKE_TOKEN, {});
    } catch (error: any) {
      if (error.response?.data?.message != undefined) {
        console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
        handleOpenModal(error.response.data.message);
      } else {
        setErrorMessage(error.message);
        handleOpenModal(error.message);
      }
    } finally {
      navigate("/");
    }
  };

  return (
    <MainContainer title={"Settings"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <header className="x-header for-subpage">
            <h1>Settings</h1>
          </header>
          <main className="x-main for-subpage">
            <div className="x-settings-wrapper">
              <h2>Hi, {profile?.preferred_name || profile?.name}</h2>
              <div className="x-settings-grid">
                <a
                  href="/account"
                  className="x-settings-grid-link w-inline-block"
                >
                  <img
                    src="images/account-circle-fill.svg"
                    alt=""
                    className="x-settings-grid-icon"
                  />
                  <div>
                    <div id="w-node-_81689ffd-3cc6-7cdb-c736-61746235f624-f92588b4">
                      Account
                    </div>
                    <div className="x-small-text">
                      Manage your personal details.
                    </div>
                  </div>
                </a>
                <a
                  href="/security"
                  className="x-settings-grid-link w-inline-block"
                >
                  <img
                    src="images/lock-password-fill.svg"
                    alt=""
                    className="x-settings-grid-icon"
                  />
                  <div>
                    <div id="w-node-_286fcc75-f112-e5b3-d780-1bb4958875f9-f92588b4">
                      Security
                    </div>
                    <div className="x-small-text">
                      Manage your account password and login settings.
                    </div>
                  </div>
                </a>
                <a
                  href="/addresses"
                  className="x-settings-grid-link w-inline-block"
                >
                  <img
                    src="images/map-pin-3-fill.svg"
                    alt=""
                    className="x-settings-grid-icon"
                  />
                  <div>
                    <div id="w-node-_6455e6b0-f812-5493-edac-bf09eed0c969-f92588b4">
                      Addresses
                    </div>
                    <div className="x-small-text">
                      Manage your Pickup and Return addresses.
                    </div>
                  </div>
                </a>
                <a
                  onClick={(e) => handleLogout(e)}
                  className="x-settings-grid-link w-inline-block"
                >
                  <img
                    src="images/logout-box-r-line.svg"
                    alt=""
                    className="x-settings-grid-icon"
                  />
                  <div>
                    <div id="w-node-_29ff5b1f-f81c-f01e-61f0-86a5897a117b-f92588b4">
                      Log Out
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </main>
          <HomeFooter />
        </div>
        <Footer />
      </div>
      {/* MODAL SECTION */}
      <div
        data-toggletarget="dialog-addons-guide"
        className={`x-dialog ${showModal ? "" : "for-hide"}`}
      >
        <div className="x-dialog-wrapper">
          <div
            className={`x-dialog-form w-form ${showModal ? "" : "for-hide"}`}
          >
            <form
              id="wf-form-Guide-Dialog-Form"
              name="wf-form-Guide-Dialog-Form"
              data-name="Guide Dialog Form"
              method="get"
              data-wf-page-id="64fc904b6e1ec367837abe0b"
              data-wf-element-id="cfb25b3e-6032-c0c4-861a-dc1b5db263d9"
            >
              <div className="x-dialog-header">
                <div className="x-dialog-title for-action-icon"></div>
                <div className="x-btn-container">
                  <a
                    onClick={(e) => handleCloseModal(e)}
                    className="x-icon-btn for-simple is-close-modal w-button"
                  >
                    
                  </a>
                </div>
              </div>
              <div className="x-dialog-body">
                <div className="x-form-wrapper">
                  <div className="x-form-row">{generateModalContent()}</div>
                  <div className="x-form-action">
                    <div className="x-btn-container">
                      <a
                        onClick={(e) => handleCloseModal(e)}
                        className="x-btn for-secondary is-close-modal w-button"
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="x-dialog-overlay" />
        </div>
      </div>
    </MainContainer>
  );
}

export default SettingsMain;
